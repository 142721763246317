

import {Options, Vue} from "vue-class-component";
import AuthorizeUserComponent from "@/components/account/AuthorizeUserComponent.vue";
import {ViewNames} from "@/router";
@Options({
  name: "Login.vue",
  components: {AuthorizeUserComponent},
})

export default class Login extends Vue{
  onLoginSuccess(){
    if(this.$route.query.redirect) {
      this.$router.push(this.$route.query.redirect as string);
    }
    else if(this.$route.query.redirectViewName){
      this.$router.push({
        name: this.$route.query.redirectViewName as string
      })
    } else{
      this.$router.push({
        name:ViewNames.Home
      });
    }
  }

  onSignupSuccess(){
    if(this.$route.query.redirect) {
      this.$router.push(this.$route.query.redirect as string);
    }
    else if(this.$route.query.redirectViewName){
      this.$router.push({
        name: this.$route.query.redirectViewName as string
      })
    } else{
      this.$router.push({
        name:ViewNames.Home
      });
    }
  }
}
