import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb8931d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg" }
const _hoisted_2 = { class: "login-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthorizeUserComponent = _resolveComponent("AuthorizeUserComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AuthorizeUserComponent, {
        "mission-type": "login",
        onSignupSuccess: _ctx.onSignupSuccess,
        onLoginSuccess: _ctx.onLoginSuccess
      }, null, 8, ["onSignupSuccess", "onLoginSuccess"])
    ])
  ]))
}